import "../css/style.css";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
/*import {
    API_POST_ScoringGet,
    API_GET_DropDown,
    API_POST_VideoTeaGet,
} from "../global/constants.js";*/
import { FormattedMessage } from "react-intl"; //多國語言套件

function Account() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [dataTable, setDataTable] = useState([
        {
            "UserID": "10",
            "UserIDNo": "曾耿弘(工程部)",
            "UserName":"",
            "UserPhone": "0937428440",
            "CityNo":"",
            "AreaNo":"",
            "BirDay": "1/4/1966",
            "Email": "201001@summit-edu.com.tw",
            "UserPoint":"",
            "CreateDate": "11/1/2023 10:22:13"							
        },
        {
            "UserID": "12",
            "UserIDNo": "黃貴蘭",
            "UserName":"",
            "UserPhone": "091000000",
            "CityNo":"",
            "AreaNo":"",
            "BirDay": "1/2/1951",
            "Email": "150402@summit-edu.com.tw",
            "UserPoint":'',
            "CreateDate": "13/1/2023 10:25:19"
        },
    ]);
    const [search, setSearch] = useState({
        "OrderNo": "",//--訂單編號
        "SDate": "", //--訂單時間起日
        "EDate": "", //--訂單時間迄日
        "OnlineState": ""//--批閱狀態
    }); //搜索條件
    const [deltaPoint, setDeltaPoint] = useState(0);
    const [whoEver, setWhoEver] = useState('');

    function getData() {

    }
    useEffect(() => {
        //檢查是否登入
        
        getData();
        getTable()
    }, []);
    function getTable() {

    }
    //搜索條件變更
    function searchState(e, vName) {
        setSearch(function (prev) {
            let newSearch = { ...prev };
            switch (vName) {
                case "OrderNo":
                    newSearch.OrderNo = e.target.value;
                    break;
                case "SDate":
                    newSearch.SDate = e.target.value;
                    break;
                case "EDate":
                    newSearch.EDate = e.target.value;
                    break;
                case "OnlineState":
                    newSearch.OnlineState = e.target.value;
                    break;
            }
            return newSearch;
        });
    }

    function hideModal() {
        $("#sys_EZP").modal('hide');
        $("#sys_AddAcc").modal('hide');
    }
    //呼叫贈送點數視窗
    function addEZP(hoster) {
        $("#sys_EZP").modal('show');
        dataTable.map((value) => {
            if (value.UserID == hoster) {
                setWhoEver(value.UserIDNo);
            }
        })
        
    }
    //變更點數數量
    function changeEZP(val) {
        setDeltaPoint(val);
    }
    //更新輕鬆幣
    function updateEZP() {
        alert("成功");
        //成功
        hideModal();
    }

    function callAccModal() {
        $("#sys_AddAcc").modal('show'); 
    }



    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //測試用function
    function consoleFuc() {
        console.log(dataTable)
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">
                    
                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span htmlFor="orderNo">帳號：</span>
                                <input id="orderNo" type="text" placeholder="xxxxx" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>

                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary mx-2" onClick={getTable} >查詢</button>
                            </div>

                            <div className="col-auto ml-auto">
                                <button type="button" className="btn btn-primary mx-2" onClick={callAccModal} >新增帳號</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">帳號</th>
                                    <th scope="col" className="text-primary">名字</th>
                                    <th scope="col" className="text-primary">身分證</th>
                                    <th scope="col" className="text-primary">電話</th>
                                    <th scope="col" className="text-primary">生日</th>
                                    <th scope="col" className="text-primary">信箱</th>
                                    <th scope="col" className="text-primary">操作</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {dataTable.map((value, key) => {
                                    if (!value) return null;
                                    if (key < curPage * eachPage || key > (curPage + 1) * eachPage - 1) { return }
                                    return (
                                        <tr key={key} >	
                                            {/*帳號*/}
                                            <td>{value.UserID}</td>
                                            {/*名字*/}
                                            <td>{value.UserIDNo}</td>
                                            {/*身分證*/}
                                            <td>{value.UserName}</td>
                                            {/*電話*/}
                                            <td>{value.UserPhone}</td>
                                            {/*生日*/}
                                            <td>{value.BirDay}</td>
                                            {/*信箱*/}
                                            <td>{value.Email}</td>
                                            {/*操作*/}
                                            <td scope="col">
                                                <button className="btn btn-outline-primary" onClick={(e) => addEZP(value.UserID) } >贈送輕鬆點</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                    <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>


            {/*<!--贈送輕鬆幣-->*/}
            <div className="modal fade" id="sys_EZP" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>贈送對象：
                                                </td>
                                                <td>
                                                    {whoEver}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <label>
                                                        本次新增點數：
                                                        <input type="number" id="pointContain" step="1" onChange={(e) => changeEZP(e.target.value)} ></input>
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" class="mx-4 btn btn-primary" onClick={updateEZP} >送出</button>
                                    <button type="button" class="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--贈送輕鬆幣 END-->*/}


            {/*<!--新增帳號-->*/}
            <div className="modal fade" id="sys_AddAcc" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>帳號：
                                                </td>
                                                <td>
                                                    <input type="text" className="formcontroll" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>權限：
                                                </td>
                                                <td>
                                                    <select>
                                                        <option>一般</option>
                                                        <option>客服</option>
                                                        <option>管理</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" class="mx-4 btn btn-primary" onClick={updateEZP} >送出</button>
                                    <button type="button" class="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--新增帳號 END-->*/}
        </>
    );
}


export default Account;