import "../css/style.css";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
/*import {
    API_POST_ScoringGet,
    API_GET_DropDown,
    API_POST_VideoTeaGet,
} from "../global/constants.js";*/
import { FormattedMessage } from "react-intl"; //多國語言套件

function Promote() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [dataTable, setDataTable] = useState([
        {
            "plainName": "學校老師試用方案",
            "plainCode": "TS",
            "plainContent": "精緻批改",
            "plainContentAMT": "5",
            "plainDuration": "30",
            "plainDurationUnity": "天",
        },{
            "plainName": "老師給學生試用方案",
            "plainCode": "SS",
            "plainContent": "精緻批改",
            "plainContentAMT": "1",
            "plainDuration": "30",
            "plainDurationUnity": "天",
        },{
            "plainName": "公司同仁試用方案",
            "plainCode": "EM",
            "plainContent": "標準批改",
            "plainContentAMT": "10",
            "plainDuration": "90",
            "plainDurationUnity": "天",
        },
    ]);
    const [search, setSearch] = useState({
        "plainOrder":"",
        "plainCode": ""
    }); //搜索條件

    function getData() {

    }
    useEffect(() => {
        //檢查是否登入
        
        getData();
        getTable()
    }, []);
    function getTable() {

    }
    //搜索條件變更
    function searchState(e, vName) {
        setSearch(function (prev) {
            let newSearch = { ...prev };
            switch (vName) {
                case "plainOrder":
                    newSearch.plainOrder = e.target.value;
                    break;
                case "plainCode":
                    newSearch.plainCode = e.target.value;
                    break;
            }
            return newSearch;
        });
    }

    function hideModal() {
        $("#sys_editCoin").modal('hide');
        $("#sys_editPlain").modal('hide');
        $("#sys_editRec").modal('hide');
    }
    //呼叫優惠碼
    function editPlain(hoster) {
        $("#sys_editCoin").modal('show');
        /*dataTable.map((value) => {
            if (value.ToupID == hoster) {
                setWhoEver(value.UserIDNo);
            }
        })*/
        
    }
    //更新優惠碼
    function updateCoupon() {
        //成功
        hideModal();
    }
    //產生紀錄
    function genDocument(hoster) {
        $("#sys_editCoin").modal('show');
        /*dataTable.map((value) => {
            if (value.ToupID == hoster) {
                setWhoEver(value.UserIDNo);
            }
        })*/
        
    }
    //更新輕鬆幣
    function getDoc() {
        //成功
        hideModal();
    }

    //呼叫新增方案視窗
    function plainManage() {
        $("#sys_editPlain").modal('show');

    }
    //送出新增方案資料
    function updatePlain() {
        $("#sys_editPlain").modal('hide');
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //測試用function
    function consoleFuc() {
        console.log(dataTable)
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">
                    
                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span htmlFor="orderNo">方案名稱：</span>
                                <input id="orderNo" type="text" placeholder="adb123" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="orderNo">代碼：</span>
                                <input id="orderNo" type="text" placeholder="xx" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>

                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary mx-2" onClick={getTable} >搜索</button>
                            </div>

                            <div className="col-auto ml-auto">
                                <button type="button" className="btn btn-info mx-2" onClick={plainManage} >新增促銷方案</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">No.</th>
                                    <th scope="col" className="text-primary">方案名稱</th>
                                    <th scope="col" className="text-primary">代碼</th>
                                    <th scope="col" className="text-primary">方案內容</th>
                                    <th scope="col" className="text-primary">使用期限</th>
                                    <th scope="col" className="text-primary">優惠碼</th>
                                    <th scope="col" className="text-primary">優惠碼紀錄</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {dataTable.map((value, key) => {
                                    if (!value) return null;
                                    //if (key < curPage * eachPage || key > (curPage + 1) * eachPage - 1) { return }
                                    return (
                                        <tr key={key} >	
                                            {/*No*/}
                                            <td>{key+1}</td>
                                            {/*方案*/}
                                            <td>{value.plainName}</td>
                                            {/*代碼*/}
                                            <td>{value.plainCode}</td>
                                            {/*方案內容*/}
                                            <td>{value.plainContent + value.plainContentAMT+"次"}</td>
                                            {/*使用期限*/}
                                            <td>{value.plainDuration + value.plainDurationUnity}</td>
                                            {/*優惠碼*/}
                                            <td>
                                                <button className="btn btn-outline-primary" onClick={(e) => editPlain(value.plainCode)} >產生</button>
                                            </td>
                                            {/*優惠碼紀錄*/}
                                            <td>
                                                <button className="btn btn-outline-primary" onClick={(e) => genDocument(value.plainCode)} >下載</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/*換頁*/}{/*<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >*/}
                    {/*    <PageNav alLength={tableLength} eachPage={eachPage} />*/}
                    {/*</div>*/}
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                    <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>


            {/*<!--產生優惠碼-->*/}
            <div className="modal fade" id="sys_editCoin" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>預留編輯空間A
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={updateCoupon} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--產生優惠碼 END-->*/}

            {/*<!--產生紀錄-->*/}
            <div className="modal fade" id="sys_editRec" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>預留編輯空間B
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={getDoc} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--產生紀錄 END-->*/}

            {/*<!--方案編輯-->*/}
            <div className="modal fade" id="sys_editPlain" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    方案編輯
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={updatePlain} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--方案編輯 END-->*/}

        </>
    );
}


export default Promote;