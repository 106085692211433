import "../css/style.css";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
/*import {
    API_POST_ScoringGet,
    API_GET_DropDown,
    API_POST_VideoTeaGet,
} from "../global/constants.js";*/
import { FormattedMessage } from "react-intl"; //多國語言套件

function Account() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [dataTable, setDataTable] = useState([
        {
            "ToupID": "1",
            "ToupOrd": "1",
            "TopupMoney": "3000",
            "ToupPoints": "3000",
            "GiftPoints": "3600",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "30/11/2022 10:56:16",
            "CreateAcnt": "",
            "ToupCaption": "3000幣贈3000紅利",
            "ToupType": "P"
        },
        {
            "ToupID": "2",
            "ToupOrd": "2",
            "TopupMoney": "2000",
            "ToupPoints": "2000",
            "GiftPoints": "2200",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "30/11/2022 10:56:23",
            "CreateAcnt": "",
            "ToupCaption": "2000幣贈2000紅利",
            "ToupType": "P"
        },
        {
            "ToupID": "3",
            "ToupOrd": "3",
            "TopupMoney": "1000",
            "ToupPoints": "1000",
            "GiftPoints": "1000",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "30/11/2022 10:56:46",
            "CreateAcnt": "",
            "ToupCaption": "1000幣贈1000紅利",
            "ToupType": "P"
        },
        {
            "ToupID": "4",
            "ToupOrd": "4",
            "TopupMoney": "500",
            "ToupPoints": "500",
            "GiftPoints": "500",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "30/11/2022 10:56:55",
            "CreateAcnt": "",
            "ToupCaption": "500幣贈500紅利",
            "ToupType": "P"
        },
        {
            "ToupID": "6",
            "ToupOrd": "5",
            "TopupMoney": "300",
            "ToupPoints": "3",
            "GiftPoints": "0",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "14/2/2023 15:04:54",
            "CreateAcnt": "",
            "ToupCaption": "大神預測專案",
            "ToupType": "1"
        },
        {
            "ToupID": "7",
            "ToupOrd": "6",
            "TopupMoney": "1800",
            "ToupPoints": "6",
            "GiftPoints": "0",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "14/2/2023 15:05:20",
            "CreateAcnt": "",
            "ToupCaption": "臨陣磨槍專案",
            "ToupType": "2"
        },
        {
            "ToupID": "8",
            "ToupOrd": "7",
            "TopupMoney": "3600",
            "ToupPoints": "6",
            "GiftPoints": "0",
            "ToupStartDate": "30/11/2022 00:00:00",
            "ToupEndDate": "31/12/9999 00:00:00",
            "CreateDate": "14/2/2023 15:05:59",
            "CreateAcnt": "",
            "ToupCaption": "登峰造極專案",
            "ToupType": "3"
        },
        {
            "ToupID": "9",
            "ToupOrd": "8",
            "TopupMoney": "0",
            "ToupPoints": "1",
            "GiftPoints": "0",
            "ToupStartDate": "30/1/2023 00:00:00",
            "ToupEndDate": "31/3/2023 00:00:00",
            "CreateDate": "24/2/2023 08:35:29",
            "CreateAcnt": "",
            "ToupCaption": "免費體驗專案",
            "ToupType": "9"
        },
        {
            "ToupID": "10",
            "ToupOrd": "9",
            "TopupMoney": "0",
            "ToupPoints": "1170",
            "GiftPoints": "0",
            "ToupStartDate": "15/3/2023 00:00:00",
            "ToupEndDate": "15/5/2023 00:00:00",
            "CreateDate": "15/3/2023 10:10:36",
            "CreateAcnt": "",
            "ToupCaption": "員工免費體驗",
            "ToupType": "P"
        }
    ]);
    const [search, setSearch] = useState({
        "OrderNo": "",//--訂單編號
        "SDate": "", //--訂單時間起日
        "EDate": "", //--訂單時間迄日
        "OnlineState": ""//--批閱狀態
    }); //搜索條件

    function getData() {

    }
    useEffect(() => {
        //檢查是否登入
        
        getData();
        getTable()
    }, []);
    function getTable() {

    }
    //搜索條件變更
    function searchState(e, vName) {
        setSearch(function (prev) {
            let newSearch = { ...prev };
            switch (vName) {
                case "OrderNo":
                    newSearch.OrderNo = e.target.value;
                    break;
                case "SDate":
                    newSearch.SDate = e.target.value;
                    break;
                case "EDate":
                    newSearch.EDate = e.target.value;
                    break;
                case "OnlineState":
                    newSearch.OnlineState = e.target.value;
                    break;
            }
            return newSearch;
        });
    }

    function hideModal() {
        $("#sys_editCoin").modal('hide');
        $("#sys_editPlain").modal('hide');
    }
    //呼叫贈送點數視窗
    function editPlain(hoster) {
        $("#sys_editCoin").modal('show');
        /*dataTable.map((value) => {
            if (value.ToupID == hoster) {
                setWhoEver(value.UserIDNo);
            }
        })*/
        
    }
    //更新輕鬆幣
    function updateCoin() {
        //成功
        hideModal();
    }

    //呼叫新增方案視窗
    function plainManage() {
        $("#sys_editPlain").modal('show');

    }
    //送出新增方案資料
    function updatePlain() {
        $("#sys_editPlain").modal('hide');
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //測試用function
    function consoleFuc() {
        console.log(dataTable)
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">
                    
                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span htmlFor="orderNo">方案搜索：</span>
                                <input id="orderNo" type="text" placeholder="adb123" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>

                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary mx-2" onClick={getTable} >搜索</button>
                            </div>

                            <div className="col-auto ml-auto">
                                <button type="button" className="btn btn-info mx-2" onClick={plainManage} >新增方案</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">No.</th>
                                    <th scope="col" className="text-primary">方案</th>
                                    <th scope="col" className="text-primary">金額</th>
                                    <th scope="col" className="text-primary">內容</th>
                                    <th scope="col" className="text-primary">生效日</th>
                                    <th scope="col" className="text-primary">結束日</th>
                                    <th scope="col" className="text-primary">創建時間</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {dataTable.map((value, key) => {
                                    if (!value) return null;
                                    if (key < curPage * eachPage || key > (curPage + 1) * eachPage - 1) { return }
                                    return (
                                        <tr key={key} >	
                                            {/*No*/}
                                            <td>{value.ToupID}</td>
                                            {/*方案*/}
                                            <td>{value.ToupCaption}</td>
                                            {/*金額*/}
                                            <td>{value.TopupMoney}</td>
                                            {/*內容*/}
                                            <td>{(value.ToupType == "P"
                                                ?
                                                (() => {
                                                    var outPut = "";
                                                    if (value.ToupPoints) {
                                                        outPut = value.ToupPoints + "輕鬆幣"
                                                    }
                                                    if (value.GiftPoints > 0) {
                                                        outPut += "+"+ value.GiftPoints + "紅利";
                                                    }
                                                    return outPut;
                                                }) ()
                                                :
                                                `${value.ToupPoints}次 ${value.ToupCaption}`
                                                )}
                                            </td>
                                            {/*生效日*/}
                                            <td>{value.ToupStartDate}</td>
                                            {/*結束日*/}
                                            <td>{value.ToupEndDate}</td>
                                            {/*創建時間*/}
                                            <td>{value.CreateDate}</td>
                                            {/*操作*/}
                                            <td scope="col">
                                                <button className="btn btn-outline-primary" onClick={(e) => editPlain(value.ToupID) } >編輯</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                    <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>


            {/*<!--贈送輕鬆幣-->*/}
            <div className="modal fade" id="sys_editCoin" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>預留編輯空間
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={updateCoin} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--贈送輕鬆幣 END-->*/}

            {/*<!--方案編輯-->*/}
            <div className="modal fade" id="sys_editPlain" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    方案編輯
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={updatePlain} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--方案編輯 END-->*/}

        </>
    );
}


export default Account;